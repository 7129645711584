/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQueryClient } from "react-query";
import { MenuComponent } from "../../../../../../_metronic/assets/ts/components";
import {
  getUserId,
  getUserRole,
  ID,
  KTSVG,
  QUERIES,
  ROLES,
} from "../../../../../../_metronic/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { deleteBugReporting } from "../../core/_requests";
//import { deleteStakeholder } from "../../core/_requests";


type Props = {
  id: ID;
  createdBy: ID;
};

const BugReportingActionsCell: FC<Props> = ({ id, createdBy }) => {
  const { setItemIdForUpdate,  setItemIdForDelete} = useListView();
  const { query } = useQueryResponse();
  const queryClient = useQueryClient();
  const userId = getUserId();
  const userRole = getUserRole();



  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const openEditModal = () => {
    if (createdBy === userId || userRole == ROLES.Superadmin.name || userRole == ROLES.MOH_PIU.name || userRole == ROLES.CEB.name)
    setItemIdForUpdate(id);
  };

  const deleteItem  = () => {
    setItemIdForDelete(id)
  };
const intl = useIntl()
  return (
    <>
      <a
        href="#"
        className="btn btn-light btn-active-light-primary btn-sm"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        {<FormattedMessage id="ACTIONS" />}
        <KTSVG
          path="/media/icons/duotune/arrows/arr072.svg"
          className="svg-icon-5 m-0"
        />
      </a>
      {/* begin::Menu */}
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
        data-kt-menu="true"
      >
        {/* begin::Menu item */}
        <div className="menu-item px-3">

          <a className="menu-link px-3" onClick={openEditModal}>
            {(userId == createdBy || userRole == ROLES.Superadmin.name || userRole == ROLES.MOH_PIU.name || userRole == ROLES.CEB.name) ? intl.formatMessage({ id: 'UPDATE' }) : intl.formatMessage({ id: 'VIEW' })}
          </a>

        </div>

        {/* end::Menu item */}

        {/* begin::Menu item */}

        {(userId == createdBy || userRole == ROLES.Superadmin.name || userRole == ROLES.MOH_PIU.name || userRole == ROLES.CEB.name) ? <div className="menu-item px-3">
          <a
            className="menu-link px-3"
            data-kt-users-table-filter="delete_row"
            onClick={deleteItem}
          >
            {<FormattedMessage id="DELETE" />}
          </a>
        </div> : null}


        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  );
};

export { BugReportingActionsCell };
