import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatTRY } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
  site: SiteModel
}
const InterimInvoiceAmountTryCell: FC<Props> = ({ site }) => (

  <div className='d-flex align-items-center'>
    {site.interimInvoiceAmountTry !== null && site.interimInvoiceAmountTry !== undefined &&
    formatTRY(site.interimInvoiceAmountTry.toFixed(2))}
  </div>

)
export { InterimInvoiceAmountTryCell }
