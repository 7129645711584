import {Column} from 'react-table'
import {SiteCustomHeader} from './SiteCustomHeader'
import {SiteModel} from '../../../core/_models'
import { FormattedMessage } from 'react-intl'
import { PaymentOrderNoCell } from './PaymentOrderNoCell'
import { AdvanceAmountCell } from './AdvanceAmountCell'
import { AdvanceInterimInvoiceCell } from './AdvanceInterimInvoiceCell'
import { AmountInvoicePaidTryCell } from './AmountInvoicePaidTryCell'
import { CurrencyRateCell } from './CurrencyRateCell'
import { AmountInvoicePaidEurCell } from './AmountInvoicePaidEurCell'
import { DateOfPaymentOrder } from './DateOfPaymentOrder'
import { ContractDescriptionCell } from './ContractDescriptionCell'

const sitesColumns: ReadonlyArray<Column<SiteModel>> = [
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CONTRACT.DESCRIPTION'/>}  className='min-w-200px' />,
    id: 'CONTRACTDESCRIPTION',
    Cell:({...props}) => <ContractDescriptionCell site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'PAYMENT.ORDER.NO'/>}  className='min-w-200px' />,
    id: 'SITENUMBERIDENTIFICATION',
    Cell:({...props}) => <PaymentOrderNoCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'ADVANCE.AMOUNT'/>}  className='min-w-200px' />,
    id: 'BUILDINGNAME',
    Cell:({...props}) => <AdvanceAmountCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'ADVANCE.SET.OFF.AGAINST.INTERIM.INVOICE'/>}  className='min-w-200px' />,
    id: 'CONTRACTSTATUS',
    Cell:({...props}) => <AdvanceInterimInvoiceCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'AMOUNT.OF.INVOICE.PAID.TRY'/>} className='min-w-200px' />,
    id: 'CONTRACTORNAME',
    Cell:({...props}) => <AmountInvoicePaidTryCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CURRENCY.RATE'/>}  className='min-w-200px' />,
    id: 'WORKSCOMPLETIONPERCENTAGE',
    Cell:({...props}) => <CurrencyRateCell site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'AMOUNT.OF.INVOICE.PAID.EUR'/>}  className='min-w-200px' />,
    id: 'CRITICALPATH',
    Cell:({...props}) => <AmountInvoicePaidEurCell site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'DATE.OF.PAYMENT.ORDER'/>}  className='min-w-200px' />,
    id: 'NOTES',
    Cell:({...props}) => <DateOfPaymentOrder site={props.data[props.row.index]} />,
  }
  
  
]

export {sitesColumns}
