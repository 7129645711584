import { FC } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatEUR } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
  site: SiteModel
}
const WorksRenovationCell: FC<Props> = ({ site }) => {

  return (
    <div className='d-flex align-items-center'>
      {site.worksForRenovationEur != null && site.worksForRenovationEur != undefined && formatEUR(site.worksForRenovationEur.toFixed(2))}
    </div>
  )
}
export { WorksRenovationCell }
