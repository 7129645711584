import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatEUR } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
  site: SiteModel
}
const ContractSignedAmountEurCell: FC<Props> = ({ site }) => (

  <div className='d-flex align-items-center'>
    {site.contractSignedAmountEur !== null && site.contractSignedAmountEur !== undefined &&
    formatEUR(site.contractSignedAmountEur.toFixed(2)) }
  </div>

)
export { ContractSignedAmountEurCell }
