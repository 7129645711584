import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLang } from '_metronic/i18n/Metronici18n'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG, initialQueryState, QUERIES, getUserId, } from "../../../../../../_metronic/helpers"
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { useQuery } from 'react-query'
import { getContracts } from '../../core/_requests'


const CiapFilter = () => {
    const { query } = useQueryResponse()
    const { state, updateState } = useQueryRequest()
    const { isLoading } = useQueryResponse()
    const [ contractDescription, setContractDescription] = useState<string | undefined>();
    const userId = getUserId();

    const {
        isLoading: isLodingAllContracts,
        data: allContracts,
        error: errorAllContracts,
    } = useQuery(
        `${QUERIES.CONTRACT_LIST}-contracts-for-ciap-${userId}`,
        () => {
            return getContracts()
        },
        {
            cacheTime: 0,
            onError: (err) => {
                console.error(err)
            }
        }
    )

    const defaultState = {
         contractDescription: "",
        cityId: "",
        district: undefined,
        earthquakeAffectedArea: "",
        selectedWorksType: []
    };

    useEffect(() => {
        MenuComponent.reinitialization()

    }, [])

    const resetData = () => {
        setContractDescription(defaultState. contractDescription);
        updateState({ filter: {}, ...initialQueryState })
    }

    const filterData = () => {
        updateState(
            {
                filter: {
                     contractDescription:  contractDescription,
                },
                ...initialQueryState
            }
        )
    }

    const lang = useLang()
    const intl = useIntl();
    return (
        <>
            {/* begin::Filter Button */}
            <button
                disabled={isLoading}
                type='button'
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                {<FormattedMessage id="FILTER" />}
            </button>
            {/* end::Filter Button */}
            {/* begin::SubMenu */}
            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                {/* begin::Header */}
                <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>{<FormattedMessage id="FILTER.OPTIONS" />} </div>
                </div>
                {/* end::Header */}

                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}

                {/* begin::Content */}
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                    {/* <div className="row ">
                        <div className="col-6 mb-7"> */}

                            <div className='mb-10'>
                                <label className='form-label fs-6 fw-bold'>{<FormattedMessage id="CHOOSE.CONTRACT" />} :</label>
                                <select
                                    className='form-select form-select-solid fw-bolder'
                                    data-kt-select2='true'
                                    name=' contractDescription'
                                    data-hide-search='true'
                                    onChange={(e) => setContractDescription(e.target.value)}
                                    value={ contractDescription}
                                >
                                    <option value={""} >{<FormattedMessage id="CHOOSE.CONTRACT" />} </option>
                                    {allContracts && allContracts.data && allContracts.data.map((contract) => (
                                        contract.id && <option value={contract.contractName} key={contract.id}> {contract.contractName} </option>
                                    ))}
                                </select>
                                
                            {/* </div>
                        </div> */}
                    </div>

                    {/* begin::Actions */}
                    <div className='d-flex justify-content-end'>
                        <button
                            type='button'
                            disabled={isLoading}
                            onClick={resetData}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='reset'
                        >
                            {<FormattedMessage id="DISCARD" />}
                        </button>
                        <button
                            disabled={isLoading}
                            type='button'
                            onClick={filterData}
                            className='btn btn-primary fw-bold px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='filter'
                        >
                            {<FormattedMessage id="FILTER" />}
                        </button>
                    </div>
                    {/* end::Actions */}
                </div>
                {/* end::Content */}
            </div>
            {/* end::SubMenu */}
        </>

    )
}

export { CiapFilter }
