import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatEUR } from '_metronic/partials/widgets/tables/TenderListWidget'

type Props = {
  site: SiteModel
}
const EuGrantCell: FC<Props> = ({ site }) => (

  <div className='d-flex align-items-center'>
    {site.euGrantEur !== null && site.euGrantEur !== undefined && formatEUR(site.euGrantEur.toFixed(2))}
  </div>

)
export { EuGrantCell }
