import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatTRY } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
    site: SiteModel
}
const AdvanceInterimInvoiceCell: FC<Props> = ({ site }) => {


    return (
        <div className='d-flex align-items-center'>
            <div className='flex-grow-1'>
                {site.advanceSetOffAgainstInterimInvoiceTry !== null && site.advanceSetOffAgainstInterimInvoiceTry !== undefined && 
                formatTRY(site.advanceSetOffAgainstInterimInvoiceTry.toFixed(2))}
            </div>
        </div>
    )
}
export { AdvanceInterimInvoiceCell }
