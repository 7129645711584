import { FC ,useState} from 'react'
import {SiteModel} from '../../../core/_models'
import { formatEUR } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
    site: SiteModel
}
const AmountInvoicePaidEurCell: FC<Props> = ({site}) => {


return(
  <div className='d-flex align-items-center'>
       <div className='flex-grow-1'>
        {site.amountOfInvoicePaidEur !== undefined && site.amountOfInvoicePaidEur !== null &&
        formatEUR(site.amountOfInvoicePaidEur.toFixed(2))}
    </div>
</div>
)
}
export {AmountInvoicePaidEurCell }
