import {KTSVG, ROLES, getUserRole} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {BugReportingListFilter} from './BugReportingListFilter'
import {FormattedMessage, useIntl} from 'react-intl'

const BugReportingListToolbar = () => {
  const role = getUserRole();
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const openAddStakeholderModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <BugReportingListFilter />
      
      { <button type='button' className='btn btn-primary' onClick={openAddStakeholderModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        <FormattedMessage id ="BUGREPORTING.ADD"/>
      </button>}
    </div>
  )
}

export {BugReportingListToolbar}
