import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatEUR } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
  site: SiteModel
}
const InterimInvoiceAmountEurCell: FC<Props> = ({ site }) => (

  <div className='d-flex align-items-center'>
    {site.interimInvoiceAmountEur !== null && site.interimInvoiceAmountEur !== undefined && 
    formatEUR(site.interimInvoiceAmountEur.toFixed(2))}
  </div>

)
export { InterimInvoiceAmountEurCell }
