import { FC ,useState} from 'react'
import {SiteModel} from '../../../core/_models'
import { formatTRY } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
    site: SiteModel
}
const CurrencyRateCell: FC<Props> = ({site}) => {


return(
  <div className='d-flex align-items-center'>
       <div className='flex-grow-1'>
        {site.currencyRateEurTry2}
    </div>
</div>
)
}
export {CurrencyRateCell }
