import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../../core/QueryResponseProvider'
import { sitesColumns } from './columns/_columns'
import { SiteModel } from '../../core/_models'
import { ChecklistListLoading } from '../../components/loading/ChecklistListLoading'
import { ChecklistListPagination } from '../../components/pagination/ChecklistListPagination'
import { KTCardBody } from '../../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { formatEUR } from '_metronic/partials/widgets/tables/TenderListWidget'

const DesignPhaseTable = () => {
  const intl = useIntl()
  const sites = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data1 = useMemo(() => sites, [sites])
  const data = data1;
  const columns = useMemo(() => sitesColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  const [totalOneEUR, setTotalOneEUR] = useState<number>()
  const [totalTwoEUR, setTotalTwoEUR] = useState<number>()

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const uniqueKeys = new Set<string>();
      let total1 = 0;
      let total2 = 0;
      let total3 = 0;

      data.forEach((item) => {
        const uniqueKey = `${item.sequence}-${item.contractDescription}`;
        if (!uniqueKeys.has(uniqueKey)) {
          uniqueKeys.add(uniqueKey);
          total1 += item.euGrantEur
          total2 += item.nationalContributionEur
        }
      });

      setTotalOneEUR(total1);
      setTotalTwoEUR(total2);
    }
  }, [data]);
  return (
    <KTCardBody className='py-1'>
      <div className='table-responsive '>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<SiteModel>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-500' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<SiteModel>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Kayıt bulunanmamıştır.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <hr />
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 '>
              <th>
                {intl.formatMessage({ id: 'TOTAL.EU.GRANT' })}
              </th>
              <th>
                {intl.formatMessage({ id: 'TOTAL.NATIONAL.CONTRIBUTION' })}
              </th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-500'>
            <tr>
              <td className='text-end min-w-100px'>
                <div className='d-flex align-items-center'>
                  {totalOneEUR !== null && totalOneEUR !== undefined &&
                    formatEUR(totalOneEUR.toFixed(2))
                  }
                </div>
              </td>
              <td className='text-end min-w-100px'>
                <div className='d-flex align-items-center'>
                  {totalTwoEUR !== null && totalTwoEUR !== undefined &&
                    formatEUR(totalTwoEUR.toFixed(2))
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ChecklistListPagination />
      {isLoading && <ChecklistListLoading />}
    </KTCardBody>
  )
}

export { DesignPhaseTable }
