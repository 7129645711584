import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatTRY } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
  site: SiteModel
}
const ContractSignedAmountTryCell: FC<Props> = ({ site }) => (

  <div className='d-flex align-items-center'>
    {site.contractSignedAmountTry !== null && site.contractSignedAmountTry !== undefined 
    && formatTRY(site.contractSignedAmountTry.toFixed(2))}
  </div>

)
export { ContractSignedAmountTryCell }
